<template>
  <div class="presentation">
    <div class="presentation__block">
      <div class="presentation__text-main">
        <h3>
          У мирні часи місія EdEra — надихати освітою. Сьогодні наш фокус —
          ділитися життєво важливою, перевіреною, оперативною інформацією
          з&nbsp;цивільним населенням та розвивати навички медіаграмотності
          та&nbsp;аналізу інформації в&nbsp;умовах інформаційної війни
        </h3>
      </div>
      <div class="presentation__text-secondary">
        <h4>
          Ми залучили команду з&nbsp;8-річним досвідом розробки освітнього
          контенту в&nbsp;Україні та&nbsp;провідних експертів з&nbsp;питань
          психології та&nbsp;військової справи
        </h4>
      </div>
      <div class="presentation__image">
        <svg-icon name="welcome_image" />
      </div>
    </div>
    <!-- <div class="arrow-down" @click="goToInstagram()">
      <svg-icon name="welcome_arrow_down" />
    </div> -->
  </div>
</template>

<script>
export default {
  props: {},
  methods: {
    goToInstagram() {
      this.$router.push({ hash: '#selector' });
    },
  },
};
</script>

<style lang="sass" scoped>
.presentation
  display: flex
  flex-direction: column
  padding: 4rem 0 0rem
  @include l
    padding: 2rem 0 0rem

.presentation__block
  display: grid
  grid-template-columns: repeat( 2, 1fr )
  grid-template-rows: auto auto
  grid-gap: 3rem
  margin-bottom: 5rem
  @include l
    grid-template-columns: 1fr
    grid-gap: 5rem


  // display: flex
  // justify-content: space-between
  // gap: 8rem
  // @include l
  //   flex-direction: column
  //   min-height: 50rem

.presentation__text
  display: flex
  flex-direction: column
  gap: 6rem

.presentation__text-main
  grid-column: 1 / 2
  grid-row: 1 / 2
  @include l
    grid-column: 1 / 2
    grid-row: 1 / 2

.presentation__text-secondary
  grid-column: 1 / 2
  grid-row: 2 / 3
  @include l
    grid-column: 1 / 2
    grid-row: 3 / 4

.presentation__image
  position: relative
  grid-column: 2 / 3
  grid-row: 1 / 3
  // max-width: 60rem
  // max-height: 50rem
  // width: 100%
  // height: 100%
  margin: 0 0 auto auto
  // @include xl
  //   width: 50rem
  //   height: 50rem
  @include l
    width: 100%
    height: 30rem
    grid-column: 1 / 2
    grid-row: 2 / 3
    margin: 0
    max-width: unset
    max-height: unset
  // @include ms
  //   width: 30rem
  //   height: 30rem
  & svg
    position: absolute
    top: -2rem
    right: -3rem
    margin-bottom: auto
    width: 60rem
    height: 50rem
    @include xl
      width: 46rem
      height: 38rem
    @include l
      // margin: 0 auto
      right: unset
      left: unset
      margin-left: 50%
      transform: translateX(-50%)
    @include ms
      // margin: 0 auto
      width: 40rem
      height: 32rem


.arrow-down
  max-width: 3.4rem
  margin: 0 auto
  cursor: pointer
</style>
