<template>
  <div class="input-box">
    <input
      :id="id"
      :value="value"
      :type="fieldType"
      :placeholder="placeholder"
      :name="name"
      :required="required"
      class="text-field"
      autocomplete="off"
      :disabled="isDisabled"
      :class="{
        error: error,
        disabled: isDisabled,
        password: isPassword,
        contact: contact,
      }"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @input="onInput"
    />
    <InputError v-if="error" :error-txt="errorTxt" />
  </div>
</template>

<script>
import InputError from '@/elements/InputError.vue';

export default {
  components: {
    InputError,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    min: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    valueMask: {
      type: String,
      default: '',
    },
    isPassword: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Boolean,
      default: false,
    },
    passwordStrength: {
      type: Object,
      default: () => {},
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorTxt: {
      type: String,
      default: '',
    },
    tooltipText: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isDollarSign: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowPassword: false,
    };
  },
  computed: {
    fieldType() {
      return this.isPassword ? this.passwordFieldType : this.type;
    },
    passwordFieldType() {
      return this.isShowPassword ? 'text' : 'password';
    },
    passwordSwitcherIcon() {
      return this.isShowPassword ? 'visible-password' : 'hidden-password';
    },
  },
  methods: {
    maskHandler(event) {
      let char = String.fromCharCode(event.keyCode); // Get the character
      switch (this.valueMask) {
        case 'latin':
          if (!/^[A-Za-z]+$/.test(char)) event.preventDefault();
          // this.$emit('setError()', 'Field must contain only latin characters');
          // this.error = true;
          // this.errorTxt = 'Field must contain only latin characters';
          break;
        case 'latin-and-numbers':
          if (!/^[A-Za-z0-9]+$/.test(char)) {
            // event.preventDefault();
            // this.error = true;
            // this.errorTxt =
            //   'Field must contain only latin characters and numbers';
            this.$store.commit('SET_ERRORS', {
              [this.name]:
                'Field must contain only latin characters and numbers',
            });
          } else {
            this.$store.commit('RESET_FIELD_ERROR', this.name);
          }
          break;
        case 'positive-number':
          if (!/^[0-9.]+$/.test(char)) event.preventDefault();
          break;
        case 'positive-number-integer':
          if (!/^[0-9]+$/.test(char)) event.preventDefault();
          break;
        default:
          break;
      }
    },
    onInput(event) {
      this.$emit('input', event.target.value);
    },
    togglePassword() {
      this.isShowPassword = !this.isShowPassword;
    },
  },
};
</script>

<style lang="sass" scoped>
.input-box
  display: flex
  flex-direction: column
  width: 100%
  align-items: flex-start
  position: relative

.dollar-sign
  position: absolute
  top: 50%
  left: 1.8rem
  transform: translate(0, -50%)

.input-box__info
  display: flex
  align-items: center
  position: absolute
  top: -24px
  right: 0
  cursor: pointer

.input-box__hint
  width: 16px
  height: 16px

.text-field
  background: $white
  border: 1px solid $border-grey-color
  color: $text-primary-color
  font-size: 12px
  line-height: 18px
  font-weight: 400
  width: inherit
  height: 44px
  padding: 12px 18px
  opacity: 1
  color: $text-primary-color

.disabled
  background: $background-color
  border: none
  cursor: pointer

.dollar-sign__input
  padding-left: 3.2rem

//.input:hover
//  background-color: rgba(40, 59, 89, 0.02)
//  border: 1px solid var(--primary-color-dark-blue)
//
.input:focus
  border: 1px solid $primary-color-blue
//
//.input::-webkit-input-placeholder
//  color: var( --primary-color-dark-grey-1)
//  font-weight: normal
//
//.input:-moz-placeholder
//  color: var( --primary-color-dark-grey-1)
//  font-weight: normal
//
//.input::-moz-placeholder
//  color: var( --primary-color-dark-grey-1)
//  font-weight: normal
//
//.input:-ms-input-placeholder
//  color: var( --primary-color-dark-grey-1)
//  font-weight: normal
//
//.input::-ms-input-placeholder
//  color: var( --primary-color-dark-grey-1)
//  font-weight: normal
//
//.input::placeholder
//  color: var( --primary-color-dark-grey-1)
//  font-weight: normal

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active
  transition: background-color 5000s ease-in-out 0s
  -webkit-box-shadow: 0 0 0px 1000px var(--primary-color-white) inset

.input.error
  border: 1px solid $negative-text

.input.password
  padding-right: 70px

.password-switcher
  position: absolute
  top: 7px
  right: 18px
  display: flex

.password-strength-meter
  display: grid
  grid-template-columns: repeat(9, 1fr)
  gap: 3px
  width: 100%
  height: 6px
  margin-top: 8px

.password-strength-meter__item
  background-color: $neutral-gray-color

.password-strength-meter__item.alert
  background-color: $negative-text

.password-strength-meter__item.warning
  background-color: $warning-color

.password-strength-meter__item.success
  background-color: $success-text

//.input[type='number']
//  -moz-appearance: textfield

.contact
  background-color: transparent
  color: $white
  border: 1.4px solid #FFFFFF
  border-radius: 6px
  font-size: 1.6rem
  &::placeholder
    color: $white
    font-size: 1.6rem
</style>
