<template>
  <div class="ticker">
    <p>Оперативна та перевірена освітня інформація</p>
    <svg-icon class="ticker-star" name="ticker_star" />
    <p>
      Навички медіаграмотності та аналізу інформації в умовах інформаційної
      війни
    </p>
    <svg-icon class="ticker-star" name="ticker_star" />
    <p>Оперативна та перевірена освітня інформація</p>
    <svg-icon class="ticker-star" name="ticker_star" />
    <p>
      Навички медіаграмотності та аналізу інформації в умовах інформаційної
      війни
    </p>
    <svg-icon class="ticker-star" name="ticker_star" />
    <p>Оперативна та перевірена освітня інформація</p>
    <svg-icon class="ticker-star" name="ticker_star" />
    <p>
      Навички медіаграмотності та аналізу інформації в умовах інформаційної
      війни
    </p>
    <svg-icon class="ticker-star" name="ticker_star" />
    <p>Оперативна та перевірена освітня інформація</p>
    <svg-icon class="ticker-star" name="ticker_star" />
    <p>
      Навички медіаграмотності та аналізу інформації в умовах інформаційної
      війни
    </p>
    <svg-icon class="ticker-star" name="ticker_star" />
    <p>Оперативна та перевірена освітня інформація</p>
    <svg-icon class="ticker-star" name="ticker_star" />
    <p>
      Навички медіаграмотності та аналізу інформації в умовах інформаційної
      війни
    </p>
    <svg-icon class="ticker-star" name="ticker_star" />
    <p>Оперативна та перевірена освітня інформація</p>
    <svg-icon class="ticker-star" name="ticker_star" />
    <p>
      Навички медіаграмотності та аналізу інформації в умовах інформаційної
      війни
    </p>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="sass" scoped>
$duration: 80s
.ticker
  // display: flex
  // align-items: center
  // gap: 5rem
  -webkit-animation-iteration-count: infinite
  -webkit-animation-timing-function: linear
  -webkit-animation-name: ticker
  -webkit-animation-duration: $duration
  white-space: nowrap
  color: #DBC8B1
  width: 500%
  & *
    transform: translate(-800rem, 0)
    font-size: 9.6rem
    line-height: 10rem
    display: inline-block
  @include l
    display: none

.ticker-star
  width: 100%
  max-height: 8rem
  max-width: 8rem
  margin: 0 2rem

@-webkit-keyframes ticker
  0%
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)
    visibility: visible
  100%
    -webkit-transform: translate3d(100%, 0, 0)
    transform: translate3d(100%, 0, 0)

@keyframes ticker
  0%
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)
    visibility: visible
  100%
    -webkit-transform: translate3d(100%, 0, 0)
    transform: translate3d(100%, 0, 0)
</style>
