<template>
  <div class="delete-confirm">
    <h3>Видалити фрейм назавжди?</h3>
    <div class="delete-confirm__button">
      <ButtonBase @click="confirm()">Так</ButtonBase>
      <ButtonBase transparent @click="close()">Ні</ButtonBase>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters('modal', ['getModalProps']),
  },
  methods: {
    async confirm() {
      await this.$store.dispatch('deleteFrame', this.getModalProps.title);
      await this.$store.dispatch('getAllFramesData');

      this.$store.commit('modal/SET_MODAL', {
        state: false,
      });
    },
    close() {
      this.$store.commit('modal/SET_MODAL', {
        state: false,
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.delete-confirm
  display: flex
  flex-direction: column
  background-color: $white
  padding: 2rem
  gap: 5rem
  text-align: center

.delete-confirm__button
  display: flex
  justify-content: center
  gap: 2rem
</style>
