<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <h2>Контакти</h2>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-for="(contact, id) in contacts" :key="id" class="contacts">
          <div v-if="contact.contact" class="contact">
            <h3>Контакт</h3>
            <h5>{{ contact.contact }}</h5>
            <h3>Ім'я</h3>
            <h5>{{ contact.name }}</h5>
            <h3>Текст</h3>
            <h5>{{ contact.text }}</h5>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      contacts: '',
    };
  },
  computed: {},
  async created() {
    this.contacts = await this.$store.dispatch('getContacts');
  },
  methods: {
    // async save() {
    //   await this.$store.dispatch('updateAmountOfViews', this.amountOfViews);
    //   this.amountOfViews = await this.$store.dispatch('getAmountOfViews');
    // },
  },
};
</script>

<style lang="sass" scoped>
.contact
  display: flex
  flex-direction: column
  border-bottom: 1px solid $border-grey-color
  margin: 2rem 0

.text__block
  display: flex
  flex-direction: column
  width: 30rem
</style>
