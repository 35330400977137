<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <h2>Завантажити новий фрейм</h2>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="upload">
          <div class="image__block">
            <PhotoUpload
              v-model="images"
              :is-error="false"
              error-text="error"
              :photos-uploaded="images"
              title="title"
              is-multiple
              :valid-formats="['png']"
              :max-size-k-b="2000"
            />
          </div>
          <div class="title__block">
            <LabelField id="title" title="Заголовок" />
            <TextField
              id="title"
              v-model.trim="frameTitle"
              name="text"
              type="text"
              tooltip-text=""
            />
          </div>
          <div class="text__block">
            <LabelField id="title" title="Текст" />
            <textarea
              id="text-area"
              v-model="text"
              name="text-area"
              rows="6"
            ></textarea>
          </div>
          <div class="category-select__block">
            <div v-for="(button, id) in categoryButtons" :key="id">
              <ButtonBase
                :transparent="!button.clicked"
                @click="toggleItem(button)"
                >{{ button.title }}</ButtonBase
              >
            </div>
          </div>
          <ButtonBase
            :disabled="images.length == 0 || !frameTitle || !categoriesSelected"
            class="upload__button"
            @click="uploadFrame"
            >Завантажити</ButtonBase
          >
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import PhotoUpload from '@/elements/PhotoUpload.vue';

export default {
  components: {
    PhotoUpload,
  },
  props: {},
  data() {
    return {
      images: [],
      frameTitle: '',
      text: '',
      categoryButtons: [
        { title: 'Психологія війни', clicked: false },
        { title: 'Домедична допомога', clicked: false },
        { title: 'Корисні поради', clicked: false },
        { title: 'Військова справа', clicked: false },
      ],
    };
  },
  computed: {
    categoriesSelected() {
      let categoriesSelected = [];
      this.categoryButtons
        .filter((el) => el.clicked === true)
        .forEach((element) => {
          categoriesSelected.push(element.title);
        });
      return categoriesSelected.join(',');
    },
  },
  methods: {
    toggleItem(button) {
      button.clicked = !button.clicked;
    },
    async uploadFrame() {
      const numberOfFramesUploaded = await this.$store.dispatch(
        'getNumberOfFrames'
      );
      let documentForUpload = {};
      documentForUpload.order = numberOfFramesUploaded;
      documentForUpload.text = this.text;
      documentForUpload.categories = this.categoriesSelected;
      for (let i = 0; i < this.images.length; i++) {
        let payload = {
          image: this.images[i],
          frameTitle: this.frameTitle,
          filename: `image_${i}.png`,
        };
        await this.$store.dispatch('uploadImage', payload);
        let imageURL = await this.$store.dispatch(
          'getImageURL',
          `images/${payload.frameTitle}/${payload.filename}`
        );
        documentForUpload[`image_${i}`] = imageURL;
      }
      let payload = {
        frameTitle: this.frameTitle,
        documentForUpload,
      };
      await this.$store.dispatch('writeDoc', payload);
      this.images = [];
      this.frameTitle = '';
      this.text = '';
      await this.$store.dispatch('getAllFramesData');
    },
  },
};
</script>

<style lang="sass" scoped>
.upload
  display: flex
  flex-direction: column
  gap: 2rem
  margin-bottom: 10rem

.upload__button
  max-width: 20rem

.text__block
  display: flex
  flex-direction: column

.category-select__block
  display: flex
  gap:2rem
  flex-wrap: wrap

textarea
  background: $white
  border: 1px solid $border-grey-color
  color: $text-primary-color
  font-size: 12px
  line-height: 18px
  font-weight: 400
  padding: 12px 18px

textarea::active
  border: 1px solid $border-grey-color
</style>
