<template>
  <button
    :class="cssClasses"
    class="btn"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <!-- <svg
      v-if="loading"
      class="loader"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="
        margin: auto;
        background: rgb(0, 0, 0);
        display: block;
        shape-rendering: auto;
      "
      width="20px"
      height="20px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#ffffff"
        stroke-width="10"
        r="35"
        stroke-dasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg> -->
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    // gray: {
    //   type: Boolean,
    //   default: false,
    // },
    loading: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    big: {
      type: Boolean,
      default: false,
    },
    red: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    category: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Boolean,
      default: false,
    },
    contactTransparent: {
      type: Boolean,
      default: false,
    },
    categoryTransparent: {
      type: Boolean,
      default: false,
    },
    // color: {
    //   type: String,
    //   required: true,
    // },
    // size: {
    //   type: String,
    //   required: true,
    // },
  },
  computed: {
    cssClasses() {
      return {
        'disabled-btn': this.disabled,
        'transparent-btn': this.transparent,
        'big-btn': this.big,
        'red-btn': this.red,
        'white-btn': this.white,
        'contact-btn': this.contact,
        'category-btn': this.category,
        'category-transparent-btn': this.categoryTransparent,
        'contact-transparent-btn': this.contactTransparent,
        // gray: this.gray,
      };
    },
  },
};
</script>

<style lang="sass">
.btn
  display: flex
  position: relative
  align-items: center
  justify-content: center
  padding: 8px 24px
  background-color: $text-primary-color
  font-weight: 500
  font-size: 18px
  line-height: 18px
  color: $white
  cursor: pointer
  border: 1.4px solid $text-primary-color
  border-radius: 6px
  // transition: $transition-speed

.transparent-btn
  background-color: rgba(81, 80, 80, 0)
  color: $text-primary-color
  transition: all $transition-speed ease
  &:hover
    background-color: $text-primary-color
    color: $white
    & a
      color: $white

.white-btn
  background-color: transparent
  color: $white
  border: 1.4px solid $white
  transition: all $transition-speed ease
  &:hover
    background-color: $white
    color: $text-primary-color

.contact-btn
  background-color: $white
  color: #9A95A3
  border: 1.4px solid $white
  transition: all $transition-speed ease
  &:hover
    background-color: #9A95A3
    color: $white

.category-btn
  background-color: $category-background
  border: 1.4px solid $category-background
  @include m
    font-size: 16px
    height: 3rem
    padding: 0.4rem 1rem


.category-transparent-btn
  background-color: $white
  border: 1.4px solid $category-background
  color: $category-background

.contact-transparent-btn
  background-color: #9A95A3
  color: $white
  border: 1.4px solid $white
  transition: all $transition-speed ease
  &:hover
    background-color: $white
    color: #9A95A3

.big-btn
  font-size: 30px
  @include xl
    padding: 8px 14px
  @include ms
    font-size: 22px


.red-btn
  background-color: $negative-text
  color: $white

.loader
  position: absolute
  left: 1rem

.disabled-btn
  // background-color: $border-grey-color
  cursor: not-allowed
  color: $text-grey-color
  background-color: $neutral-gray-color

// .gray
  // background-color: $border-grey-color
  // color: $black
</style>
